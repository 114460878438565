/* eslint-disable no-magic-numbers */
import { Capacitor } from "@capacitor/core";
import { PhoneNumberFormat, PhoneNumberUtil } from "google-libphonenumber";
import { compareTwoStrings } from "string-similarity";
import { createSelectorCreator, defaultMemoize } from "reselect";
import { memoize } from "decko";
import Constants from "const/Constants";
import Countries from "const/Countries";
import moment from "moment";
import objectHash from "object-hash";

const phoneNumberUtil = PhoneNumberUtil.getInstance();

const BASE_DATE_FORMATS = [
  "D MMM YYYY",
  "D MMMM YYYY",
  "D-M-YYYY",
  "D-MM-YYYY",
  "D.MM.YYYY",
  "D/M/YYYY",
  "D/MM/YYYY",
  "DD - M - YYYY",
  "DD - MM - YY",
  "DD - MM - YYYY",
  "DD - MMM - YYYY",
  "DD MMM YYYY",
  "DD MMMM YYYY",
  "DD-M-YYYY",
  "DD-MM-YY",
  "DD-MM-YYYY",
  "DD-MMM-YYYY",
  "DD. MM. YYYY",
  "DD.MM.YYYY",
  "DD/M/YYYY",
  "DD/MM/YYYY",
  "Dth MMMM YYYY",
  "Dth of MMMM, YYYY",
  "M - D - YYYY",
  "M - DD - YYYY",
  "M-D-YYYY",
  "M-DD-YYYY",
  "MM - D - YYYY",
  "MM - DD - YYYY",
  "MM-D-YYYY",
  "MM-DD-YYYY",
  "MMM D, YYYY",
  "MMM DD, YYYY",
  "MMMM D YYYY",
  "MMMM D, YYYY",
  "MMMM DD YYYY",
  "MMMM DD, YYYY",
  "YYYY - MM - DD",
  "YYYY-MM-DD"
];

const US_DATE_FORMATS = [
  "M/D/YYYY",
  "M/DD/YYYY",
  "MM / DD / YYYY",
  "MM/D/YYYY",
  "MM/DD/YYYY"
];

const CA_DATE_FORMATS = [
  "YY - MM - DD",
  "YY-MM-DD"
];

const DEFAULT_ANIMATION_DURATION = 300;

const easeInOut = (time) => time > 0.5 ? (4 * Math.pow((time - 1), 3)) + 1 : 4 * Math.pow(time, 3);

export default class Utils {
  @memoize
  static getClientType() {
    return Utils.checkIsTouchDevice() ? Constants.CLIENT_TYPES.MOBILE : Constants.CLIENT_TYPES.DESKTOP;
  }

  @memoize
  static getAverageNumber(numbers) {
    return numbers.reduce(
      (aggregator, value) => {
        return aggregator + (value / numbers.length);
      },
      0
    );
  }

  static getPropertyByPath(object, path, fallback) {
    const pathFragments = (path ? (Array.isArray(path) ? path : path.split(".")) : []);

    if (!pathFragments.length) return fallback;

    const result = pathFragments.reduce((res, key) => res && res[key], object);

    return result === undefined ? fallback : result;
  }

  static getProps(object, keys, defaults = {}) {
    return keys.reduce((result, key) => {
      const value = Utils.getPropertyByPath(object, key, defaults[key]);

      if (value !== undefined) result[key] = value;

      return result;
    }, {});
  }

  @memoize
  static getProcessEnvVars() {
    return Object.fromEntries(
      Object.entries(process.env).map(([key, value]) => {
        return [key.indexOf("REACT_APP") === 0 ? key.split("REACT_APP_").pop() : key, value];
      })
    );
  }

  @memoize
  static getMobilePlatformType() {
    if (/Android/i.test(window.navigator.userAgent)) {
      return Constants.PLATFORM_TYPES.ANDROID;
    }
    if (/iPad|iPhone|iPod|CriOS/.test(window.navigator.userAgent) && !window.MSStream) {
      return Constants.PLATFORM_TYPES.IOS;
    }

    return null;
  }

  @memoize
  static getNativePlatformType() {
    return Utils.checkIsNativePlatform() ? Capacitor.getPlatform() : null;
  }

  static getFlagIconUrl(countryCode) {
    return `${Utils.getProcessEnvVars().SVG_FLAG_ICONS_URL}/${countryCode}.svg`;
  }

  static getDocumentAmountData(amountVatRates, amountBase, amountVat, amountCorrection = 0) {
    const defaultAmountVatRates = [{ rate: 0, base: (+amountBase || 0) + (+amountVat || 0), value: 0 }];

    amountVatRates = (amountVatRates || []).length ? amountVatRates : defaultAmountVatRates;

    const totalBaseValue = amountVatRates.reduce((aggregator, currentValue) => aggregator + currentValue.base || 0, 0);

    const totalVatValue = amountVatRates.reduce((aggregator, currentValue) => aggregator + currentValue.value || 0, 0);

    return { base: totalBaseValue, vat: totalVatValue, total: totalBaseValue + totalVatValue + amountCorrection };
  }

  static getCurrencySymbol(currency) {
    const languageCodes = [
      ...(window.navigator.languages || []),
      Constants.DEFAULT_NUMBERS_LOCALE
    ];

    return (0).toLocaleString(
      languageCodes,
      {
        style: "currency",
        currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      }
    ).replace(/\d/g, "").trim();
  }

  static setTimeout(callback, interval, ...restArgs) {
    const timeoutId = window.setTimeout(callback, interval, ...restArgs);

    return () => clearTimeout(timeoutId);
  }

  static setInterval(callback, interval, ...restArgs) {
    const intervalId = window.setInterval(callback, interval, ...restArgs);

    return () => clearInterval(intervalId);
  }

  @memoize
  static stringShortener(string, maxLength, joiner = "...") {
    if (!string) return "";
    if (!maxLength || string.length <= maxLength) return string;

    const length = Math.floor((maxLength - joiner.length) / 2);

    return `${string.slice(0, length)}${joiner}${string.slice(-length)}`;
  }

  static checkDeepEquality(objA, objB) {
    return objectHash({ value: objA }) === objectHash({ value: objB });
  }

  static checkSimilarityBy(searchBy = {}, target, similarityCoef = Constants.DATA_SIMILARITY_COEFFICIENT) {
    const { id, ...restSearchBy } = searchBy;

    const fieldsList = Object.entries(restSearchBy);

    if (id && id === target.id) return true;
    if (fieldsList.length === 0) return false;
    if (similarityCoef === 1) {
      return fieldsList.some(([fieldKey, fieldValue]) => {
        if (!target[fieldKey] || !fieldValue) return false;

        return target[fieldKey].trim().toLowerCase() === fieldValue.trim().toLowerCase();
      });
    }

    return fieldsList.some(([fieldKey, fieldValue]) => {
      const stringA = target[fieldKey] && `${target[fieldKey]}`.trim().toLowerCase();

      const stringB = fieldValue && `${fieldValue}`.trim().toLowerCase();

      return (stringA && fieldValue) && (stringA === stringB || compareTwoStrings(stringA, stringB) > similarityCoef);
    });
  }

  @memoize
  static checkIsDefaultAppDomain() {
    return window.location.origin === Utils.getProcessEnvVars().DEFAULT_APP_URL;
  }

  @memoize
  static checkIsVercelDomain() {
    return window.location.origin.endsWith("vercel.app");
  }

  @memoize
  static checkIsDevMode() {
    return Utils.getProcessEnvVars().NODE_ENV === Constants.NODE_ENV_TYPES.DEVELOPMENT;
  }

  @memoize
  static checkIsLocalhost() {
    return window.location.hostname === "localhost";
  }

  @memoize
  static checkIsStagingEnv() {
    return Utils.getProcessEnvVars().ENV_TYPE === Constants.ENV_TYPES.STAGING;
  }

  @memoize
  static checkIsProductionEnv() {
    return Utils.getProcessEnvVars().ENV_TYPE === Constants.ENV_TYPES.PRODUCTION;
  }

  @memoize
  static checkIsTouchDevice() {
    return "ontouchstart" in window;
  }

  @memoize
  static checkIsNativePlatform() {
    return Capacitor.isNativePlatform();
  }

  @memoize
  static checkIsNativeAndroid() {
    return Utils.getNativePlatformType() === Constants.PLATFORM_TYPES.ANDROID;
  }

  @memoize
  static checkIsNativeIos() {
    return Utils.getNativePlatformType() === Constants.PLATFORM_TYPES.IOS;
  }

  @memoize
  static checkIsMongoDbId(string) {
    return /^[a-f\d]{24}$/i.test(string);
  }

  static formatApiDate(date, restrictToMonth = false) {
    const { DATETIME_FORMATS: { API_DATE, API_MONTH_DATE } } = Constants;

    return moment.utc(date).format(restrictToMonth ? API_MONTH_DATE : API_DATE);
  }

  static formatNoTimeZoneDate(date, utc = true) {
    return (utc ? moment.utc(date) : moment(date)).format(Constants.DATETIME_FORMATS.NO_TIMEZONE_FORMAT);
  }

  static parseDate(rawValue, countryCode, currency) {
    const { CA, US } = Countries;

    const caLocale = currency === "CAD" || countryCode === CA;

    const usLocale = currency === "USD" || countryCode === US;

    const dateLocale = caLocale ? CA : (usLocale ? US : null);

    const formats = dateLocale === US
      ? [...US_DATE_FORMATS, ...BASE_DATE_FORMATS, ...CA_DATE_FORMATS]
      : (
        dateLocale === CA
          ? [...CA_DATE_FORMATS, ...BASE_DATE_FORMATS, ...US_DATE_FORMATS]
          : [...BASE_DATE_FORMATS, ...US_DATE_FORMATS, ...CA_DATE_FORMATS]
      );

    const parsedDate = rawValue ? moment.utc(rawValue, formats, true) : null;

    if (parsedDate && parsedDate.isValid()) return parsedDate;

    return null;
  }

  @memoize
  static normalizeAngle(angle) {
    return Math.round((Constants.CIRCLE_DEGREES - angle) / Constants.CORRECT_DOCUMENT_ROTATION_DEGREES)
      * Constants.CORRECT_DOCUMENT_ROTATION_DEGREES % Constants.CIRCLE_DEGREES;
  }

  @memoize
  static createDeepEqualSelectorCreator() {
    return createSelectorCreator(
      defaultMemoize,
      Utils.checkDeepEquality
    );
  }

  static createDeepEqualSelector(...args) {
    return Utils.createDeepEqualSelectorCreator()(...args);
  }

  static deferredRun(func, ...args) {
    setTimeout(func, 0, ...args);
  }

  static downloadContent(content, fileName = "") {
    const linkElement = window.document.createElement("a");

    linkElement.setAttribute("href", content);
    linkElement.setAttribute("download", fileName);
    linkElement.click();
  }

  @memoize
  static normalizePhoneNumber(phone, countryCode) {
    let parsedPhone;

    try {
      parsedPhone = phoneNumberUtil.parse(phone);
    } catch (errorA) {
      if (countryCode) {
        try {
          parsedPhone = phoneNumberUtil.parse(phone, countryCode);
        } catch (errorB) {
          return null;
        }
      } else {
        return null;
      }
    }
    if (phoneNumberUtil.isValidNumber(parsedPhone)) {
      return phoneNumberUtil.format(parsedPhone, PhoneNumberFormat.E164);
    }

    return null;
  }

  @memoize
  static parseQueryString(query) {
    const envVars = {};

    if (query) {
      query.replace(/^\?/, "").split("&").forEach((keyValuePair) => {
        let [key, value] = keyValuePair.split("=");

        if (value === "null") value = null;
        else if (value === "false") value = false;
        else value = value === undefined ? true : decodeURIComponent(value);
        envVars[key] = value;
      });
    }

    return envVars;
  }

  static objectToQueryString(paramObj, withoutUrlEncode = false) {
    if (!paramObj) return "";

    return Object.keys(paramObj).map((key) => {
      const values = paramObj[key];

      if (!key || values === undefined || values === null) return null;

      return (Array.isArray(values) ? values : [values]).map((value) => {
        return `${withoutUrlEncode ? key : encodeURIComponent(key)}${
          value === true ? "" : `=${(withoutUrlEncode ? value : encodeURIComponent(value))}`}`;
      });
    }).filter(Boolean).join("&");
  }

  static replaceTextVars(text, replacements) {
    let newText = text || "";

    Object.keys(replacements).forEach((prop) => {
      newText = newText.replace(new RegExp(`{{${prop}}}`, "g"), replacements[prop]);
    });

    return newText;
  }

  static toMoneyString(money, currency, fractionDigits = 2) {
    if (isNaN(money) || !money) money = 0;
    if (currency && currency !== "undefined") {
      const languageCodes = [
        ...(window.navigator.languages || []),
        Constants.DEFAULT_NUMBERS_LOCALE
      ];

      try {
        return money.toLocaleString(
          languageCodes,
          {
            currency,
            style: "currency",
            minimumFractionDigits: fractionDigits,
            maximumFractionDigits: fractionDigits
          }
        );
      } catch (error) {
        return `${(money || 0).toFixed(fractionDigits)} ${currency}`;
      }
    }

    return money.toFixed(fractionDigits);
  }

  @memoize
  static toMoneyNumber(number, useRound = true, precision = "..") {
    const mathMethod = useRound ? Math.round : Math.floor;

    const precisionMultiplier = Math.pow(Constants.DECIMALS_MULTIPLIER, precision.length);

    return mathMethod((+number + Number.EPSILON) * precisionMultiplier) / precisionMultiplier;
  }

  @memoize
  static capitalizeText(text) {
    if (!text) return null;

    return text[0].toUpperCase() + text.substring(1);
  }

  @memoize
  static uncapitalizeText(text) {
    if (!text) return null;

    return text[0].toLowerCase() + text.substring(1);
  }

  static storageValue(key, value, useSessionStorage = false) {
    const storage = useSessionStorage ? window.sessionStorage : window.localStorage;

    try {
      if (value === null) {
        storage.removeItem(key);

        return null;
      }
      if (value !== undefined) {
        storage.setItem(key, value);

        return value;
      }

      return storage.getItem(key);
    } catch (error) {
      return null;
    }
  }

  static cookieValue(key) {
    const cookies = Object.fromEntries(document.cookie.split("; ").map((cookie) => {
      const [cookieKey, cookieValue] = cookie.split("=");

      return [cookieKey, cookieValue];
    }));

    return cookies[key];
  }

  static storageJsonValue(key, value) {
    if (value === null) {
      Utils.storageValue(key, value);

      return null;
    }
    if (value !== undefined) {
      Utils.storageValue(key, JSON.stringify(value));

      return value;
    }

    return Utils.parseJson(Utils.storageValue(key));
  }

  @memoize
  static parseJson(string) {
    try {
      return JSON.parse(string);
    } catch (error) {
      return null;
    }
  }

  static preloadImages(imagesUrls) {
    return Promise.all(imagesUrls.map((imageUrl) => {
      return new Promise((resolve, reject) => {
        const image = new Image();

        const handleLoadComplete = () => resolve(image);

        image.src = imageUrl;
        if (image.complete || image.readyState === XMLHttpRequest.DONE) handleLoadComplete();
        else {
          image.addEventListener("load", handleLoadComplete);
          image.addEventListener("error", () => reject());
        }
      });
    }));
  }

  static arrayUpdateItem(array, uniqKey, uniqKeyValue, update) {
    const updateFunction = typeof update === "function" ? update : () => update;

    return array && array.map((item) => (item[uniqKey] === uniqKeyValue ? updateFunction(item) : item));
  }

  static arrayRemoveItem(array, uniqKey, uniqKeyValue) {
    return array && array.filter((item) => item[uniqKey] !== uniqKeyValue);
  }

  static arrayUpdateItemById(array, id, update) {
    return Utils.arrayUpdateItem(array, "id", id, update);
  }

  static arrayRemoveItemById(array, id) {
    return Utils.arrayRemoveItem(array, "id", id);
  }

  static arrayFind(array, uniqKey, uniqKeyValue, fallback) {
    return (array && array.find((item) => item[uniqKey] === uniqKeyValue)) || fallback;
  }

  static arrayFindById(array, id, fallback) {
    return Array.isArray(array) && Utils.arrayFind(array, "id", id, fallback);
  }

  static arraySort(array, props, orders) {
    if (!array) return array;

    const sortProps = Array.isArray(props) ? props : [props];

    const sortOrders = Array.isArray(orders) ? orders : [orders];

    return [...array].sort((elA, elB) => {
      return sortProps.reduce((result, current, index) => {
        if (result) return result;

        const typeFunction = typeof current === "function";

        const [propA, propB] = typeFunction
          ? [current(elA), current(elB)]
          : [elA[current], elB[current]];

        if (propA < propB) {
          return sortOrders[index] ? -1 : 1;
        }

        if (propA > propB) {
          return sortOrders[index] ? 1 : -1;
        }

        return 0;
      }, 0);
    });
  }

  static debounce(func, wait, immediate) {
    let timeout;

    return (...args) => {
      const later = () => {
        timeout = null;
        if (!immediate) func(...args);
      };

      const callNow = immediate && !timeout;

      clearTimeout(timeout);

      timeout = setTimeout(later, wait);

      if (callNow) func(...args);
    };
  }

  static parseFloat(arg = "") {
    if (typeof arg === "number") {
      return arg;
    }

    if (typeof arg === "string") {
      return parseFloat(arg.replace(",", ".")) || 0;
    }

    return parseFloat(arg);
  }

  static deepEqual(objectA, objectB) {
    if (objectA === objectB) return true;
    if (!objectA || !objectB) return false;

    const typeStringA = Object.prototype.toString.call(objectA);

    const typeStringB = Object.prototype.toString.call(objectB);

    if (typeStringA !== typeStringB || typeof objectA !== "object") {
      return false;
    }

    if (Array.isArray(objectA)) {
      return objectA.length === objectB.length
        && objectA.every((item, index) => Utils.deepEqual(item, objectB[index]));
    }

    const keysA = Object.keys(objectA);

    const keysB = Object.keys(objectB);

    return (
      keysA.length === keysB.length
        && keysA.every((key) => Utils.deepEqual(objectA[key], objectB[key]))
    );
  }

  static objectMap(target, iterator) {
    if (!target) return target;

    if (Array.isArray(target)) return target.map(iterator);

    return Object.fromEntries(
      Object.entries(target).map(([key, value], ...args) => {
        return [key, iterator(value, ...args)];
      })
    );
  }

  static animate(frame, duration = DEFAULT_ANIMATION_DURATION, easing = easeInOut, onEnd) {
    let canceled = false;

    const start = Date.now();

    const next = () => {
      if (canceled) return;

      const delta = Date.now() - start;

      if (delta < duration) {
        frame(easing(delta / duration));
        window.requestAnimationFrame(next);

        return;
      }
      frame(1);
      if (onEnd) onEnd();
    };

    window.requestAnimationFrame(next);

    return () => {
      canceled = true;
    };
  }

  static handleStopEventPropagation(event) {
    event.stopPropagation();
  }

  @memoize
  static escapeStringRegexp(string) {
    return string.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&");
  }
}
